(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/common/assets/javascripts/helpers.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/common/assets/javascripts/helpers.js');
"use strict";


const {
  BrowserStorageConstants
} = svs.poker_react.components.common.constants;
const isMobile = svs.core.detect.formfactor.mobile();
const isLoggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
const {
  constants: {
    EXPIRES_10M
  },
  browser: BrowserStorage
} = svs.components.Storage;
const pokerHome = svs.core.urlMapping.get('pokerHome');
const trackPokerPlayEvent = (gameLink, tournamentName) => {
  var _ref;
  const trackingLabel = (_ref = gameLink !== null && gameLink !== void 0 ? gameLink : tournamentName) !== null && _ref !== void 0 ? _ref : 'SPELA DIREKT';
  svs.components.analytics.trackEvent({
    category: 'Poker',
    action: tournamentName ? 'Upcoming events' : 'initiate poker',
    opt_label: trackingLabel
  });
};

const launchWebClient = function () {
  let {
    gameLink,
    tournamentId,
    tournamentName,
    shouldTrackEvent = true
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (shouldTrackEvent) {
    trackPokerPlayEvent(gameLink, tournamentName);
  }
  if (!isLoggedIn) {
    BrowserStorage.set(BrowserStorageConstants.OpenWebClientKey, BrowserStorageConstants.Domain, {
      openWebClient: true,
      params: {
        gameLink,
        tournamentId
      }
    }, EXPIRES_10M, () => {
      svs.components.customer_login.login();
    });
  } else {
    const qs = new URLSearchParams();
    qs.append('formFactor', isMobile ? 'mobile' : 'desktop');
    if (gameLink) {
      qs.append('gameLink', gameLink);
    }
    if (tournamentId) {
      qs.append('tournamentId', tournamentId);
    }
    window.open("".concat(pokerHome, "/spela?").concat(qs.toString()), '', 'popup=true,width=1280,height=720');
  }
};
const helpers = {
  launchWebClient,
  trackPokerPlayEvent
};
setGlobal('svs.poker_react.components.common.helpers', helpers);

 })(window);