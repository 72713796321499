(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/common/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/common/assets/javascripts/constants.js');
"use strict";


const BrowserStorageConstants = {
  OpenWebClientKey: 'openWebClient',
  Domain: 'poker'
};
const MenuTabTypes = {
  News: 'aktuellt',
  Ranking: 'poy-ranking',
  Widget: 'widgetyta'
};
const widgetAreaPrefix = 'pokerWidgetArea';
const StaticWidgetArea = {
  News: 'pokerWidgetArea_aktuellt',
  SEO_Optimization: 'pokerWidgetArea_seo_optimization',
  Ranking: 'pokerWidgetArea_poy'
};
const TournamentFilters = [{
  id: 'private',
  title: 'Våra turneringar'
}, {
  id: 'network',
  title: 'Andra turneringar'
}, {
  id: 'poker-sm',
  title: 'Poker-SM'
}, {
  id: 'poy',
  title: 'POY'
}];
const GameTypes = [{
  id: null,
  title: 'Alla'
}, {
  id: 'Holdem',
  title: 'Texas Hold\'em'
}, {
  id: 'Omaha',
  title: 'Omaha'
}, {
  id: 'FiveCardOmaha',
  title: 'Fem kort Omaha'
}, {
  id: 'FiveCardDraw',
  title: 'Mörkpoker'
}, {
  id: 'SixPlusHoldem',
  title: '6+ Hold\'em'
}];
const BuyinFiltersSEK = [{
  id: null,
  title: 'Alla'
}, {
  id: '0-20',
  title: '0 - 20 kr',
  min: 0,
  max: 20
}, {
  id: '20-100',
  title: '20 - 100 kr',
  min: 20,
  max: 100
}, {
  id: '100-300',
  title: '100 - 300 kr',
  min: 100,
  max: 300
}, {
  id: '300+',
  title: '300kr+',
  min: 300,
  max: 20000
}];
const BuyinFiltersEUR = [{
  id: null,
  title: 'Alla'
}, {
  id: '0-2',
  title: '€0 - €2',
  min: 0,
  max: 2
}, {
  id: '2-10',
  title: '€2 - €10',
  min: 2,
  max: 10
}, {
  id: '10-30',
  title: '€10 - €30',
  min: 10,
  max: 30
}, {
  id: '30+',
  title: '€30+',
  min: 30,
  max: 2000
}];
const SatelliteFilters = [{
  value: 'All',
  label: 'Alla turneringar'
}, {
  value: 'Regular',
  label: 'Poker-SM'
}, {
  value: 'Satellite',
  label: 'Satelliter till Poker-SM'
}];
const TournamentFetchMoreCount = 10;
setGlobal('svs.poker_react.components.common.constants', {
  BrowserStorageConstants,
  MenuTabTypes,
  widgetAreaPrefix,
  StaticWidgetArea,
  TournamentFilters,
  GameTypes,
  BuyinFiltersSEK,
  BuyinFiltersEUR,
  SatelliteFilters,
  TournamentFetchMoreCount
});

 })(window);